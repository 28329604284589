.header-container {
    display: flex;
    position: relative;
    width: 100%;
}
.header-container .logo-container{
    height: 100%;
    width: 50%;
    /* padding-left: 5rem; */
    padding-top: 2rem;
}

.header-container .logo{
    height: 80px;
    width: 320px;
    background-image: url("./logo.png");
    background-size: contain;
    background-repeat: no-repeat;

}

.header-container .header-menu-container {
    width: 50%;
    /* padding-top: 3%;
    padding-right: 1%; */
    padding-top: 2rem;
}
.header-container .header-menu{
    float: left;
}
.menu-container{
    text-align: right;
}
@media screen and (max-width: 768px) {
    .header-container .logo{
        height: 40px;
        width: 320px;
        background-image: url("./logo.png");
        background-size: contain;
        background-repeat: no-repeat;
    
    }
    .header-container {
        display: flex;
        justify-content: space-around;
        position: relative;
        flex-wrap:wrap;
    }
    .header-container .header-menu-container {
        width: 100%;
        padding-top: 0rem;
    }
    .menu-container{
        font-size: 11px;
    }
    .header-container .logo{
        height: 50px;
        width: 220px;
    }
}
