.team-container {
    margin-top: 1%;
    margin-left: 15%;
}

.team-container .team-title {
    font-size: xx-large;
}

.team-container .team-items {
    width: 80%;
}
.team-container  .list-data{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.team-container  .list-content {
    width: calc(100% - 300px);
    /* display: inline-block; */
    padding-left:20px;
}

.team-container  .list-img {
    width: 272px;
    /* display: inline-block; */
}

@media screen and (max-width: 768px) {
    .team-container  .list-content {
        width: 100%;
        padding-left:0px;
    }
    .team-container {
        margin-left: 5%;
    }

}
