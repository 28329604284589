
.home-container {
    height: 72vh;
    width: 100%;
}

.slogan-container {
    text-align: center;
    padding:1rem;
}

.carousel-container {
    height: 75vh;
    width: 100vw;
    /* background-image: url("WechatIMG23150.jpeg");
    background-size: 100% 100%; */
}
.carousel-item{
    height: 70vh;
    color: #fff;
    line-height: 70vh;
    text-align: center;
    background:  center;
    background-repeat: no-repeat;
  }
.carousel-item1{
    background-image: url("../../images/1.jpg");
    background-size: 100% 100%;
   
}
.carousel-item2{
    background-image: url("../../images/2.jpg");
    background-size: cover ;
  }
  .carousel-item3{

    background-image: url("../../images/3.jpg");
    background-size: cover;
  }
  .carousel-item4{

    background-image: url("../../images/4.jpg");
    background-size: cover;
  }
  .carousel-item5{

    background-image: url("../../images/5.jpg");
    background-size: cover;
  }