.research-container {
    margin-top: 1%;
    margin-left: 15%;
}

.research-container .research-title {
    font-size: xx-large;
}

.research-container .research-items {
    width: 80%;
}

.research-content
{
    margin-right: 20%;
}

.footer {
    margin-right: 20%;
}